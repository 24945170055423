import axios from 'axios';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import {
  constructQueryString,
  getLambdaApiUrl,
  getStandardHeader,
  processApiError,
} from '../../utils';
import { enableMockResponses, mockGetAwsAccountIdResponse } from './mockResponse';

const mock = enableMockResponses;

export const getAwsAccountId = async (token: CognitoToken, awsAccountName: string): Promise<string> => {
  if (mock) {
    return Promise.resolve(mockGetAwsAccountIdResponse);
  }

  return axios.get(`${getLambdaApiUrl()}/conduit/findAwsAccount?${constructQueryString({ awsAccountName })}`,
    { headers: getStandardHeader(token) })
    .then((result) => result.data.identifier?.accountId)
    .catch((error) => processApiError('getAwsAccountId', error));
};
