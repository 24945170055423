import {
  AwsGenerationInput,
  AwsGenerationOutput,
} from '@amzn/alkimia-model';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import axios from 'axios';
import { getLambdaApiUrl, getStandardHeader, processApiError } from '../../utils';
import { enableMockResponses, mockUserSelectionResponse } from './mockResponse';

const mock = enableMockResponses;

export const userSelection = async (
  token: CognitoToken,
  awsGenerationInput: AwsGenerationInput,
): Promise<AwsGenerationOutput> => {
  if (mock) { return Promise.resolve(mockUserSelectionResponse); }

  return axios.post(`${getLambdaApiUrl()}/aws/generateAwsIaCv2`,
    awsGenerationInput,
    { headers: getStandardHeader(token) })
    .then((result) => result.data)
    .catch((error) => processApiError('generateAwsIaC', error));
};
