import axios from 'axios';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import { constructQueryString, getLambdaApiUrl, getStandardHeader, processApiError } from '../../utils';
import { enableMockResponses, mockGetAwsAccountNameResponse } from './mockResponse';

const mock = enableMockResponses;

export const getAwsAccountName = async (token: CognitoToken, awsAccountId: string): Promise<string> => {
  if (mock) {
    return Promise.resolve(mockGetAwsAccountNameResponse);
  }

  return axios.get(`${getLambdaApiUrl()}/conduit/getAwsAccount?${constructQueryString({ awsAccountId })}`,
    { headers: getStandardHeader(token) })
    .then((result) => result.data.account?.name)
    .catch((err) => processApiError('getAwsAccount', err, true));
};
