import {
  Checkbox,
  Container,
  Header,
} from '@amzn/awsui-components-react-v3';
// eslint-disable-next-line no-use-before-define
import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { ItemType } from './Types';

type ResourceTypeSelectionComponentProps = {
  allDistinctResourceTypes: string[];
  allTableItems: ItemType[];
  setTableItems: React.Dispatch<SetStateAction<ItemType[]>>;
}

/**
 * ResourceTypeSelectionComponent renders a selectable list of resources.
 *
 * This component displays a list of resource with checkboxes, allowing users to
 * select individual items or all items at once. It manages the selection state and
 * updates the parent component with the filtered items based on the selection.
 *
 * @param {Array} props.allDistinctResourceTypes - An array of distinct items to be displayed in the list.
 * @param {Array} props.allTableItems - The complete set of table items to filter from.
 * @param {Function} props.setTableItems - A function to update the filtered table items in the parent component.
 *
 * @returns {JSX.Element} A container with a header, "Select All" checkbox, and a list of resource type checkboxes.
 *
 * @example
 * <VerifiedResourceTypeComponent
 *   setOfVerifiedResources={distinctVerifiedResourceTypes}
 *   allTableItems={allItems}
 *   setTableItems={setFilteredItems}
 * />
 */
export function ResourceTypeSelectionComponent({
  allDistinctResourceTypes,
  allTableItems,
  setTableItems,
}: ResourceTypeSelectionComponentProps) {
  const [selectAll, setSelectAll] = useState(true);
  const [selectedResources, setSelectedResources] = useState<string[]>([]);
  const [selectAllIndeterminateState, setSelectAllIndeterminateState] = useState(false);
  const initializedSelectedResources = useRef(false);

  useEffect(() => {
    if (!initializedSelectedResources.current && allDistinctResourceTypes.length > 0) {
      setSelectedResources(allDistinctResourceTypes);
      initializedSelectedResources.current = true;
    }
  }, [allDistinctResourceTypes]);

  useEffect(() => {
    setTableItems(allTableItems.filter((i) => selectedResources.includes(i.type)));
  }, [selectedResources]);

  useEffect(() => {
    if (selectedResources.length === 0) {
      setSelectAllIndeterminateState(false);
      setSelectAll(false);
    } else if (selectedResources.length === allDistinctResourceTypes.length) {
      setSelectAllIndeterminateState(false);
      setSelectAll(true);
    } else {
      setSelectAllIndeterminateState(true);
      setSelectAll(false);
    }
  }, [selectedResources, allDistinctResourceTypes]);

  const handleSelectAllChange = () => {
    const selectAllNewState = !selectAll;
    if (selectAllNewState) {
      setSelectedResources(allDistinctResourceTypes);
    } else {
      setSelectedResources([]);
    }
    setSelectAll(selectAllNewState);
  };

  const handleCheckboxChange = (resourceType: string) => {
    if (selectedResources.includes(resourceType)) {
      setSelectedResources(selectedResources.filter((k) => k !== resourceType));
    } else {
      setSelectedResources([...selectedResources, resourceType].sort());
    }
    setSelectAll(!(selectedResources.length === 0));
  };

  return (
    <Container
      header={(
        <Header
          variant="h2"
        >
          Verified Resource Types
        </Header>
      )}
    >
      <Checkbox
        checked={selectAll}
        indeterminate={selectAllIndeterminateState}
        onChange={() => {
          handleSelectAllChange();
        }}
      >
        <div>
          <b>
            Select All
          </b>
        </div>
      </Checkbox>
      {
        allDistinctResourceTypes.map((t) => (
          <Container
            key={t}
          >
            <Checkbox
              checked={selectedResources.includes(t)}
              onChange={() => handleCheckboxChange(t)}
            >
              {t}
            </Checkbox>
          </Container>
        ))
      }
    </Container>
  );
}
