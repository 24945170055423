export class APIError extends Error {
  code: number | undefined;

  call: string | undefined;

  reason: string | undefined;

  constructor(code?: number, call?: string, reason?: string, message?: string) {
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }

    this.name = 'APIError';

    this.code = code;
    this.call = call;
    this.reason = reason;
  }

  static fromAxiosError = (err, call: string, msg: string): APIError => {
    if (err.response) {
      const { status, headers } = err.response;
      // x-amzn-errortype
      return new APIError(status, call, headers['x-amzn-errortype'], msg);
    }
    return new APIError(undefined, call, undefined, msg);
  };
}
