import { AwsResourceConfiguration, ResourceManagementAnalysisInput, ResourceManagementAnalysisOutput } from '@amzn/alkimia-model';
import axios from 'axios';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import { getLambdaApiUrl, getStandardHeader, processApiError } from '../../utils';
import { enableMockResponses, mockAnalyzeAwsResourceManagementResponse } from './mockResponse';

const mock = enableMockResponses;

export const analyzeAwsResourceManagement = async (token: CognitoToken, configs: AwsResourceConfiguration[]): Promise<ResourceManagementAnalysisOutput> => {
  if (mock) {
    return Promise.resolve(mockAnalyzeAwsResourceManagementResponse);
  }

  const input: ResourceManagementAnalysisInput = {
    resources: configs,
  };

  return axios.post(`${getLambdaApiUrl()}/aws/analyzeAwsResourceManagement`,
    input,
    { headers: getStandardHeader(token) })
    .then((result) => result.data)
    .catch((error) => processApiError('analyzeAwsResourceManagement', error));
};
