import { DiscoverAwsResourceConfigurationOutput, ScanOperations } from '@amzn/alkimia-model';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import axios from 'axios';
import {
  constructQueryString,
  getLambdaApiUrl,
  getStandardHeader,
  processApiError,
} from '../../utils';
import { enableMockResponses, mockScanAwsAccountResponse } from './mockResponse';

const mock = enableMockResponses;

// scanAwsAccount Lambda function can take up to 900 seconds.  What value will a user tolerate in a UI?
export const timeoutAfterSeconds = 900;

export const scanAwsAccount = async (
  token: CognitoToken,
  customerAccountId: string | undefined,
  customerAccountRegion: string,
): Promise<DiscoverAwsResourceConfigurationOutput> => {
  if (!customerAccountId) throw new Error('Missing required input - awsAccountId');
  if (mock) { return Promise.resolve(mockScanAwsAccountResponse); }
  return new Promise((resolve) => {
    const startTime = Date.now();
    let scanOperation = ScanOperations.startScan;
    const intervalRetry = setInterval(() => {
      axios.get(`${getLambdaApiUrl()}/datastore/scanAwsAccount?${constructQueryString({ customerAccountId, customerAccountRegion, scanOperation })}`,
        {
          headers: getStandardHeader(token),
          timeout: 4000,
        })
        .then((result) => {
          if (result.status === 200) {
            clearInterval(intervalRetry);
            resolve({ messages: [], services: result.data });
          }
          if (Date.now() - startTime > timeoutAfterSeconds * 1000) {
            clearInterval(intervalRetry);
            resolve({ messages: [`Timed out after ${timeoutAfterSeconds} seconds`], services: result.data ?? [] });
          }
          if (scanOperation === ScanOperations.startScan) {
            scanOperation = ScanOperations.getScan;
          }
        })
        .catch((error) => resolve(processApiError('scanAwsAccount', error)));
    }, 5000);
  });
};
