import axios from 'axios';
import { AwsAccount } from '@amzn/alkimia-model';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import { getLambdaApiUrl, getStandardHeader, processApiError } from '../../utils';
import { enableMockResponses, mockListAwsAccountsResponse } from './mockResponse';

const mock = enableMockResponses;

export const listAwsAccounts = async (
  token: CognitoToken,
): Promise<AwsAccount[]> => {
  if (mock) { return Promise.resolve(mockListAwsAccountsResponse); }

  return axios.get(`${getLambdaApiUrl()}/brass/listAwsAccounts`,
    { headers: getStandardHeader(token) })
    .then((result) => result.data)
    .catch((error) => processApiError('listAwsAccounts', error));
};
